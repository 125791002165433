import { Button, Grid, List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import React, { useContext, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { GalleryContext } from 'context/gallery/GalleryContext'
import { useSingleUploadMutation } from 'apollo/upload/mutations/SingleUpload.generated'
import { useTranslate } from 'react-admin'

interface IDropzoneFile {
    lastModified: number
    lastModifiedDate: Record<string, unknown>
    name: string
    path: string
    size: number
    type: string
    webkitRelativePath: string
}

const useStyles = makeStyles(() => ({
    root: {
        margin: '25px 0'
    },
    text: {
        fontWeight: 700
    },
    container: {
        padding: 50
    }
}))

const FileDropzone: React.FC = () => {
    const classes = useStyles()
    const translate = useTranslate()
    const [files, setFiles] = useState<IDropzoneFile[] | null>(null)
    const [accFiles, setAccFiles] = useState<IDropzoneFile[] | null>(null)
    const [filesAreUploaded, setFilesAreUploaded] = useState<boolean>(false)
    const [erroredFiles, setErroredFiles] = useState<IDropzoneFile[]>()
    const [error, setError] = useState<string | null>(null)
    const [singleUpload] = useSingleUploadMutation()
    const { state: galleryState, setGalleryState } = useContext(GalleryContext)
    const [startUploading, setStartUploading] = useState(false)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onDrop = (acceptedFiles: any, rejectedFiles: any) => {
        // Do something with the files
        if (rejectedFiles) {
            setErroredFiles(erroredFiles?.concat([...erroredFiles, rejectedFiles]))
        }
        setFilesAreUploaded(false)
        return setAccFiles(acceptedFiles)
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'image/jpg, image/png, image/jpeg, image/gif, image/x-eps, application/postscript, application/eps'
    })

    const RemoveFile = (name: string) => {
        setFiles(files && files.filter((file) => file.name !== name))
    }

    const uploadFiles = async () => {
        if (files?.length) {
            await singleUpload({
                variables: {
                    path: galleryState?.breadcrumbs ? galleryState.breadcrumbs : '/',
                    fileName: files[0]?.name
                        .split('.png' || '.jpg' || '.jpeg' || '.gif' || '.epi' || '.eps')[0]
                        .toLowerCase()
                        .replace(/[\W_]/g, '-')
                        .toString(),
                    file: files[0]
                }
            })
                .then(async () => {
                    setStartUploading(true)
                    return await setFiles(files.filter((_file) => _file !== files[0]))
                })
                .catch((err) => console.error('err', err))
        }
    }

    const handleUploadMedia = async () => {
        setFilesAreUploaded(false)
        uploadFiles()
    }

    useEffect(() => {
        if (files?.length && startUploading) {
            setFilesAreUploaded(false)
            uploadFiles()
        }

        if (!files?.length && startUploading) {
            setFilesAreUploaded(true)
            setStartUploading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files])

    useEffect(() => {
        if (filesAreUploaded) setGalleryState({ ...galleryState, hasUploaded: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesAreUploaded])

    useEffect(() => {
        if (files && accFiles) {
            const arr = [...files, ...accFiles]
            const filteredArray = arr.filter(
                (item, pos) => arr.findIndex((p) => p.name === item.name) === pos
            )

            if (arr.length !== filteredArray.length) {
                setError('This file already exists')
            }
            setFiles(filteredArray)
        } else if (accFiles) {
            setFiles(accFiles)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accFiles])

    return (
        <Paper variant="outlined" elevation={5} className={classes.root}>
            {files && (
                <List component="nav" className={classes.root} aria-label="mailbox folders">
                    {files.map((file: IDropzoneFile) => (
                        <ListItem divider={true} key={file.name}>
                            <ListItemText primary={file.name} />
                            <Button onClick={() => RemoveFile(file.name)}>
                                {translate('manager.gallery.upload_block.remove')}
                            </Button>
                        </ListItem>
                    ))}
                    {filesAreUploaded && (
                        <Typography variant="subtitle2" align="center">
                            {translate('manager.gallery.upload_block.successfully_uploaded')}
                        </Typography>
                    )}
                    {erroredFiles?.map((erroredFile: IDropzoneFile) => (
                        <p key={erroredFile.name}>
                            {`${translate('manager.gallery.upload_block.could_not-be_uploaded')}: ${
                                erroredFile.name
                            }`}
                        </p>
                    ))}
                </List>
            )}
            <Grid
                container
                {...getRootProps()}
                className={classes.container}
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <input {...getInputProps()} />
                <p>{error}</p>
                {isDragActive ? (
                    <p className={classes.text}>
                        {translate('manager.gallery.upload_block.drop_files_here')}
                    </p>
                ) : (
                    <p className={classes.text}>
                        {translate('manager.gallery.upload_block.drag_or_drop_files_here')}
                    </p>
                )}
            </Grid>
            <Grid container justifyContent="center">
                <Button
                    variant="contained"
                    color={files?.length ? 'primary' : undefined}
                    onClick={() => handleUploadMedia()}
                    style={{ margin: 20 }}
                >
                    {translate('manager.gallery.upload_block.upload_files')}
                </Button>
            </Grid>
        </Paper>
    )
}

const UploadBlock: React.FC = () => {
    return <FileDropzone />
}

export default UploadBlock
